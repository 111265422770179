import React from 'react';
import imgGoals1 from'../../../../assets/unesco/img/goals1.jpg';

const GoalsSection = ({props, schoolId}) => {

    return (<div className="container mb-5">

        {!schoolId? null : <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="container">
                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12">
                            <span style={{color: 'black', textAlign: 'justify'}}>
                                <p>
                                    <b>2. Metas para el final del ciclo escolar</b>
                                </p>
                                <p>
                                    <b>Estrategia de lengua</b>
                                </p>
                            </span>
                            <div className="container">
                                <div className="row align-items-center justify-content-center text-center">
                                    <div className="col-12">
                                        <table className="table responsive-table">
                                            <tbody>
                                                <tr>
                                                    <td style={{...styles.td2, ...{width:'30%', textAlign: 'left'}}}>
                                                        <b>ESTRATEGIA DE LENGUA</b>
                                                    </td>
                                                    <td style={{...styles.td2, ...{width:'20%'}}}>
                                                        <b>Primer grado</b>
                                                    </td>
                                                    <td style={{...styles.td2, ...{width:'20%'}}}>
                                                        <b>Segundo grado</b>
                                                    </td>
                                                    <td style={{...styles.td2, ...{width:'20%'}}}>
                                                        <b>Tercer grado</b>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{...styles.td2, ...{width:'20%', textAlign: 'left'}}}>
                                                        <b>Alfabetización inicial</b>
                                                    </td>
                                                    <td style={{...styles.td2, ...{width:'20%'}}}>
                                                        80% de estudiantes en nivel C
                                                    </td>
                                                    <td style={{...styles.td2, ...{width:'20%'}}}>
                                                        100% de estudiantes en nivel D
                                                    </td>
                                                    <td style={{...styles.td2, ...{width:'20%'}}}>
                                                        100% de estudiantes en nivel D
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td style={{...styles.td2, ...{width:'20%', textAlign: 'left'}}}>
                                                        <b>Adquisición de convencionalidades de la lengua escrita</b>
                                                    </td>
                                                    <td style={{...styles.td2, ...{width:'20%'}}}></td>
                                                    <td style={{...styles.td2, ...{width:'20%'}}}></td>
                                                    <td style={{...styles.td2, ...{width:'20%'}}}>
                                                        80% de estudiantes en nivel 3
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div> }
    </div>)
}

export default GoalsSection;


const styles = {
    td2: {
        padding: '0.25rem',
        textAlign: 'center',
        fontSize: '14px',
        border: '1px solid gray'
    }
}

