import React from 'react';
import TableOutline from '../../../../components/Charts/TableOutline';
import Bar from '../../../../components/Charts/Bar';
import HorizontalBar2 from '../../../../components/Charts/HorizontalBar2';
import ImgLevels from'../../../../assets/unesco/img/niveles.jpeg';
import IDAIPrimerGrado from './idai/IDAIPrimerGrado';
import IDAISegundoGrado from './idai/IDAISegundoGrado';
import IDAIICETercerGrado from './idai/IDAIICETercerGrado';

const IDAISection = ({schoolId, currentSchoolYear}) => {

    return (<div className="container mb-5">

        {!(schoolId && currentSchoolYear)? null : <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="container">

                    <div className="pagebreak" />

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5"></div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center">
                        <div className="col-12">
                            <span style={{color: 'black', textAlign: 'justify'}}>
                                <p>
                                    <b>3. Instrumento Diagnóstico de Alfabetización Inicial (IDAI). Resultados agrupados de primero, segundo y tercer grado</b>
                                </p>
                                <p>
                                    La tabla y la gráfica muestran la totalidad de niñas y niños cuyos 
                                    resultados del IDAI han sido capturados. En caso de que las cantidades 
                                    por momento no coincidan con el total de estudiantes, lo ideal es indagar 
                                    la razón por la cual no se les aplicó la evaluación o por la que no se 
                                    registró el resultado. Recuerda que es importante tener registros de todas 
                                    las niñas y niños participantes para poder tomar decisiones en su beneficio 
                                    con los datos de este reporte.
                                </p>
                            </span>
                        </div>

                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-10 mt-5">
                            <TableOutline
                                title=""
                                style = {{
                                    pagination: false
                                }}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'diagnostico2023'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        {schoolId}, 
                                        {lastSchoolYearId: currentSchoolYear.id},
                                    ]},
                                    dimensions: [
                                        {name: 'grado', label: 'Grado', priority: 1, minWidth: '10px'},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [
                                        {name: 'inicial', label: 'Momento 1', aggregation: 'count', priority: 3, minWidth: '150px'},
                                        {name: 'intermedio', label: 'Momento 2', aggregation: 'count', priority: 4},
                                        {name: 'final', label: 'Momento 3', aggregation: 'count', priority: 5},
                                    ],
                                    customMetrics: [
                                        {name: 'recordCount', label: 'Total estudiantes', aggregation: 'count', custom: '*', priority: 2},
                                    ],
                                }}
                            />
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-10 mt-5">
                            <Bar
                                title=""
                                porcent={true}
                                requestObj = {{
                                    configParams: { // DataSource (custom, table)
                                        custom: 'diagnostico2023'
                                    },
                                    dateRange: null,
                                    filters: {'$and': [
                                        {schoolId}, 
                                        {lastSchoolYearId: currentSchoolYear.id}
                                    ]},
                                    dimensions: [
                                        {name: 'grado', label: 'Grado'},
                                    ],
                                    breakdownDimension: null,
                                    metrics: [
                                        {name: 'inicial', label: 'Momento 1', aggregation: 'porc', color: '#8EB6F8'},
                                        {name: 'intermedio', label: 'Momento 2', aggregation: 'porc', color: '#FDD768'},
                                        {name: 'final', label: 'Momento 3', aggregation: 'porc', color: '#7AD693'},
                                    ],
                                    customMetrics: []
                                }}
                            />

                        </div>
                    </div>

                    <div className="pagebreak" />

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12 mt-5">
                            <span style={{color: 'black', textAlign: 'left'}}>
                                <p><b>Niveles de conceptualización de la lengua escrita</b></p>
                                <p>
                                    Los niveles asignados en el Instrumento Diagnóstico de 
                                    Alfabetización Inicial son coherentes con la perspectiva 
                                    psicogenética del desarrollo de la Lengua Escrita que ha 
                                    sido ampliamente documentado por Emilia Ferreiro desde 1979.
                                </p>
                            </span>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-10 mt-5">
                            <span style={{color: 'black', textAlign: 'left'}}>
                                <p>
                                    <img src={ImgLevels} alt="..." className="w-100" />
                                </p>
                            </span>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center">
                        <div className="col-12">
                            <span style={{color: 'black', textAlign: 'justify'}}>
                                <p>
                                    <b>4. Resultados del IDAI por grado y grupo (1o y 2o)</b>
                                </p>
                                <p>
                                    Analiza los datos para transformarlos en información: 
                                    ¿Cuántos estudiantes hay en cada nivel? ¿Para qué te sirve conocer estos
                                    resultados? Saber el número de estudiantes por nivel te ayudará a
                                    proponer acciones pedagógicas focalizadas, a organizar a tu grupo 
                                    y a seleccionar las actividades didácticas que implementarás en
                                    adelante para lograr la alfabetización de todas las niñas y niños a
                                    tu cargo.
                                </p>
                            </span>
                        </div>
                    </div>

                    <IDAIPrimerGrado schoolId={schoolId} currentSchoolYear={currentSchoolYear}/>

                    <IDAISegundoGrado schoolId={schoolId} currentSchoolYear={currentSchoolYear}/>

                    <IDAIICETercerGrado schoolId={schoolId} currentSchoolYear={currentSchoolYear}/>

                </div>
            </div>
        </div> }
    </div>)
}

export default IDAISection;
