import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SchoolReportV1 from './SchoolReportV1';
import SchoolReportV2 from './SchoolReportV2';
import SchoolReportV3 from './SchoolReportV3';
import SchoolReportV4 from './SchoolReportV4';

const SchoolReport = (props) => {

	let userState = useSelector(state => state.userState);
    const schoolId = props.match.params.id;

	return (<>

		{userState.schoolYear && (() => {
	        switch (userState.schoolYear.id) {
	          case 1:
	            return <SchoolReportV1 schoolId={schoolId} /> 
	          case 2:
	            return <SchoolReportV2 schoolId={schoolId} /> 
	          case 3:
	            return <SchoolReportV3 schoolId={schoolId} /> 
	          case 4:
	            return <SchoolReportV4 schoolId={schoolId} /> 
	          default:
	            return null
	      	}
      	})()}

	</>);
}

export default SchoolReport;
