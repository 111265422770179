import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TableOutline from '../../../../../components/Charts/TableOutline';
import TableGroups from '../../../../../components/Charts/TableGroups';
import Bar from '../../../../../components/Charts/Bar';
import HorizontalBar2 from '../../../../../components/Charts/HorizontalBar2';

const IDAISegundoGrado = ({schoolId, currentSchoolYear}) => {

    let userState = useSelector(state => state.userState);

    return (<>

        {!(schoolId && currentSchoolYear)? null : <> 
            <div className="row align-items-center justify-content-center text-center">
                <div className="col-12">
                    <span style={{color: 'black', textAlign: 'justify'}}>
                        <p>
                            <b>IDAI. Segundo grado</b>
                        </p>
                        <p>
                            Si eres docente de segundo grado, en la siguiente tabla debes ubicar 
                            la información que corresponda a tu grupo. Reflexiona sobre la distribución 
                            de aprendices por nivel y establece metas a corto plazo para que avancen en 
                            su proceso de alfabetización. ¿Qué número de estudiantes se encuentra en los 
                            niveles A y B? ¿Qué porcentaje de tu grupo representan? ¿Qué meta puedes 
                            plantearte para la segunda evaluación? Comparte este objetivo con tu figura 
                            directiva y con tus colegas para generar un diálogo pedagógico en torno al 
                            proceso de alfabetización.
                        </p>
                    </span>
                </div>
            </div>

            <div className="row align-items-center justify-content-center text-center mt-5">
                <div className="col-12 mt-5">
                    <span style={{color: 'black', textAlign: 'left'}}>
                        <p><i>Número de estudiantes por nivel y momento en IDAI, segundo grado</i></p>
                    </span>

                    <TableGroups
                        title=""
                        style = {{
                            fontSize: 'small',
                            pagination: false
                        }}
                        requestObj = {{
                            configParams: { // DataSource (custom, table)
                                custom: 'diagnostico2023'
                            },
                            dateRange: null,
                            filters: {'$and': [
                                {schoolId}, 
                                {grado: 2},
                                {lastSchoolYearId: currentSchoolYear.id}
                            ]},
                            dimensions: [
                                {name: 'grado', label: 'Grado', minWidth: '20px', priority: 1},
                                {name: 'label', label: 'Grupo', minWidth: '20px', priority: 2},
                            ],
                            breakdownDimension: null,
                            metrics: [],
                            customMetrics: [
                                {name: 'inicialA', label: 'A', header: 'Inicial', minWidth: '10px', priority: 3, aggregation: 'sum', custom: 'IF(inicial="A", 1, 0)'},
                                {name: 'inicialB', label: 'B', header: 'Inicial', minWidth: '10px', priority: 4, aggregation: 'sum', custom: 'IF(inicial="B", 1, 0)'},
                                {name: 'inicialC', label: 'C', header: 'Inicial', minWidth: '10px', priority: 5, aggregation: 'sum', custom: 'IF(inicial="C", 1, 0)'},
                                {name: 'inicialD', label: 'D', header: 'Inicial', minWidth: '10px', priority: 6, aggregation: 'sum', custom: 'IF(inicial="D", 1, 0)'},
                                {name: 'intermedioA', label: 'A', header: 'Intermedio', minWidth: '10px', priority: 8, aggregation: 'sum', custom: 'IF(intermedio="A", 1, 0)'},
                                {name: 'intermedioB', label: 'B', header: 'Intermedio', minWidth: '10px', priority: 9, aggregation: 'sum', custom: 'IF(intermedio="B", 1, 0)'},
                                {name: 'intermedioC', label: 'C', header: 'Intermedio', minWidth: '10px', priority: 10, aggregation: 'sum', custom: 'IF(intermedio="C", 1, 0)'},
                                {name: 'intermedioD', label: 'D', header: 'Intermedio', minWidth: '10px', priority: 11, aggregation: 'sum', custom: 'IF(intermedio="D", 1, 0)'},
                                {name: 'finalA', label: 'A', header: 'Final', minWidth: '10px', priority: 13, aggregation: 'sum', custom: 'IF(final="A", 1, 0)'},
                                {name: 'finalB', label: 'B', header: 'Final', minWidth: '10px', priority: 14, aggregation: 'sum', custom: 'IF(final="B", 1, 0)'},
                                {name: 'finalC', label: 'C', header: 'Final', minWidth: '10px', priority: 15, aggregation: 'sum', custom: 'IF(final="C", 1, 0)'},
                                {name: 'finalD', label: 'D', header: 'Final', minWidth: '10px', priority: 16, aggregation: 'sum', custom: 'IF(final="D", 1, 0)'},
                                {name: 'recordCount', label: 'Total estudiantes por grado', hidden:true, minWidth: '200px', aggregation: 'count', custom: '*'},
                            ],
                            order: {
                                dimension: 'label',
                                ascendent: true,
                            },
                            orderBy: 'ORDER BY label ASC',
                        }}
                    />
                </div>
            </div>

            <div className="row align-items-center justify-content-center text-center mt-5 pt-5">
                <div className="col-12">
                    <p style={{backgroundColor: '#6ea9dd', color: 'white', paddingBottom: '2rem'}}>
                        Ahora que sabes cómo está tu grupo, consulta las páginas 5 y 6 del Manual del personal docente
                        e identifica qué actividades resultan ideales para avanzar de un nivel a otro.
                    </p>
                    <span style={{color: 'black', textAlign: 'left'}}>
                        <p><i>Observa los resultados a nivel escuela</i></p>
                        <p>
                            La siguiente gráfica muestra la población total de segundo 
                            grado distribuida por nivel de alfabetización de acuerdo con el IDAI. 
                            Reflexiona de manera individual y en colectivo sobre esta distribución 
                            por niveles y sugiere metas a corto plazo para avanzar en el proceso de 
                            alfabetización en segundo grado. Por ejemplo: lograr que las y los 
                            estudiantes que están en los niveles A y B transiten a nivel C para 
                            la segunda aplicación del IDAI.
                        </p>
                    </span>
                </div>
            </div>

            <div className="row align-items-center justify-content-center text-center mt-5">
                <div className="col-10 mt-5">
                    <HorizontalBar2
                        title="Segundo Grado"
                        horizontal={true}
                        porcent={true}
                        requestObj = {{
                            configParams: { // DataSource (custom, table)
                                custom: 'responses2023'
                            },
                            dateRange: null,
                            filters: {'$and': [
                                {schoolId}, 
                                {lastSchoolYearId: currentSchoolYear.id},
                                {grado: 2},
                                {'$or': [
                                    {formId: 40},
                                    {formId: 41},
                                    {formId: 42},
                                ]},
                            ]},
                            dimension: {name: 'formName', label: ''},
                            dimensions: [{name: 'formId', label: ''}],
                            breakdownDimension: {name: 'customScore', label: 'Nivel'},
                            metrics: [],
                            customMetrics: [],
                            colorLabels: {
                                'A': '#4285F4', 
                                'B': '#EA4336', 
                                'C': '#FBBD05', 
                                'D': '#34A853',
                            },
                            order: {
                                dimension: 'formId',
                                ascendent: true,
                            },
                            orderBy: 'ORDER BY formId ASC',
                        }}
                    />
                </div>
            </div>

             <div className="row align-items-center justify-content-center text-center mt-5 pt-5">
                <div className="col-12">
                    <p style={{backgroundColor: '#fff1c7', color: 'gray', paddingBottom: '2rem'}}>
                        Ahora cuentas con información para reflexionar junto con tu comunidad educativa 
                        sobre el proceso de alfabetización de niñas y niños. En conjunto, compartan qué 
                        actividades son las que más les han funcionado.
                    </p>
                </div>
            </div>
        </>}
    </>);
}

export default IDAISegundoGrado;
