import React from 'react';
import auth from '../../../../services/users/AuthService';
import Table from '../../../../components/Charts/Table';

const SummarySection = ({props, schoolId, currentSchoolYear}) => {

    const [school, setSchool] = React.useState(null);
    const [statistics, setStatistics] = React.useState(null);

    React.useEffect(()=>{
        auth.getSchool(schoolId).then(school => {
            setSchool(school);
        }).catch(error => {
            console.log('err', error);
        });

        auth.getSchoolStatistics(schoolId, currentSchoolYear.id).then(school => {
            setStatistics(school);
        }).catch(error => {
            console.log('err', error);
        });
    }, []);


    return (<div className="container mb-5">

        <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="container">
                    <div className="row align-items-center text-center">
                        <div className="col-12">
                            <h6>
                                <p className="m-0">Política Escuela de Aprendizajes Fundamentales</p>
                                <p className="m-0">{currentSchoolYear? currentSchoolYear.name: ''}</p>
                                <b>Reporte Escuela</b><br/>
                                <b>Momento 1</b><br/>
                            </h6>
                        </div>
                    </div>
                </div>

                <div className="container">
                    
                    {!(school && statistics)? null : <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12">
                            <span style={{color: 'black', textAlign: 'left'}}>
                                <p><b>1. Datos generales de la escuela y estadística básica</b></p>
                            </span>

                            <div className="table-responsive">

                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td style={{...styles.td2, ...{width:'20%'}}}>
                                                <b>Entidad</b> <br/> {school.state_name}
                                            </td>
                                            <td style={{...styles.td2, ...{width:'20%'}}}>
                                                <b>Municipio</b> <br/> {school.municipality_name}
                                            </td>
                                            <td style={{...styles.td2, ...{width:'20%'}}}>
                                                <b>Localidad</b> <br/> {school.school_location}
                                            </td>
                                            <td style={{...styles.td2, ...{width:'5%'}}}></td>
                                            <td style={{...styles.td2, ...{width:'15%'}}}></td>
                                            <td style={{...styles.td2, ...{width:'5%'}}}><b>1º</b></td>
                                            <td style={{...styles.td2, ...{width:'5%'}}}><b>2º</b></td>
                                            <td style={{...styles.td2, ...{width:'5%'}}}><b>3º</b></td>
                                            <td style={{...styles.td2, ...{width:'5%'}}}><b>Total</b></td>
                                        </tr>
                                        <tr>
                                            <td style={styles.td2}>
                                                <b>CCT</b> <br/> {school.school_cct}
                                            </td>
                                            <td style={styles.td2}>
                                                <b>Escuela</b> <br/> {school.school_name}
                                            </td>
                                            <td style={styles.td2}>
                                                <b>Turno</b> <br/> {school.school_shift}
                                            </td>
                                            <td style={styles.td2}></td>
                                            <td style={styles.td2}><b>Estudiantes</b></td>
                                            <td style={styles.td2}>{statistics.students? statistics.students.firstGrade: ''}</td>
                                            <td style={styles.td2}>{statistics.students? statistics.students.secondGrade: ''}</td>
                                            <td style={styles.td2}>{statistics.students? statistics.students.thirdGrade: ''}</td>
                                            <td style={styles.td2}>{statistics.students? statistics.students.total: ''}</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.td2}>
                                                <b>Zona Escolar</b> <br/> {school.zone_name} - {school.zone_cct}
                                            </td>
                                            <td style={styles.td2}>
                                                <b>Nivel</b> <br/> {school.level_name}
                                            </td>
                                            <td style={styles.td2}>
                                                <b>Grado de Marginación</b> <br/> {school.school_marginalizationLevel}
                                            </td>
                                            <td style={styles.td2}></td>
                                            <td style={styles.td2}><b>Grupos</b></td>
                                            <td style={styles.td2}>{statistics.groups? statistics.groups.firstGrade: ''}</td>
                                            <td style={styles.td2}>{statistics.groups? statistics.groups.secondGrade: ''}</td>
                                            <td style={styles.td2}>{statistics.groups? statistics.groups.thirdGrade: ''}</td>
                                            <td style={styles.td2}>{statistics.groups? statistics.groups.total: ''}</td>
                                        </tr>
                                        <tr>
                                            <td style={styles.td2}>
                                                <b>Tipo de Organización</b> <br/> {school.schoolType_name}
                                            </td>
                                            <td style={styles.td2}></td>
                                            <td style={styles.td2}></td>
                                            <td style={styles.td2}></td>
                                            <td style={styles.td2}><b>Docentes</b></td>
                                            <td style={styles.td2}>{statistics.docentes? statistics.docentes.firstGrade: ''}</td>
                                            <td style={styles.td2}>{statistics.docentes? statistics.docentes.secondGrade: ''}</td>
                                            <td style={styles.td2}>{statistics.docentes? statistics.docentes.thirdGrade: ''}</td>
                                            <td style={styles.td2}>*{statistics.docentes? statistics.docentes.total: ''}</td>
                                        </tr>
                                   </tbody>
                                </table>
                            </div>
                            <span style={{float: 'right', color: '#424141'}}>*Los docentes pueden tener más de un grupo</span>
                        </div>
                    </div> }

                    <div className="row align-items-center justify-content-center text-center mt-5 pt-5">
                        <div className="col-6">
                            <span style={{color: 'black', textAlign: 'justify'}}>
                                <p>
                                    El <i>Reporte escuela</i> es la herramienta de la Política Estatal de Aprendizajes Fundamentales 
                                    que organiza los resultados del logro de los aprendizajes de niñas y niños de primero, 
                                    segundo y tercer grado de primaria. 
                                    Durante el ciclo escolar, el <i>Reporte escuela</i> se genera en tres momentos: noviembre, marzo y julio.
                                </p>
                                <br></br>
                                <p>
                                    El <i>Reporte escuela</i> del <b>Momento 1</b> brinda un <b>diagnóstico</b> para identificar el nivel
                                    de conceptualización de la escritura en el que se encuentran las niñas y los niños 
                                    <b>al inicio del ciclo escolar</b> mediante la aplicación del 
                                    <b>Instrumento Diagnóstico de Alfabetización Inicial (IDAI)</b>. 
                                </p>
                            </span>
                        </div>

                        <div className="col-6">
                            <span style={{color: 'black', textAlign: 'justify'}}>
                                <p>
                                    Asimismo, se utiliza para evaluar el manejo de ciertas convenciones de 
                                    la escritura de las y los estudiantes ya alfabetizados en tercer grado 
                                    por medio del <b>Instrumento de Convencionalidades de la Escritura (ICE)</b>.
                                </p>
                                <br></br>
                                <p>
                                    La información recuperada con los instrumentos y presentada gráficamente 
                                    facilita al colectivo docente y a las figuras directivas dar un seguimiento
                                    cercano a las y los aprendices, fijar metas de aprendizaje para el ciclo escolar 
                                    y planear acciones que permitan garantizar el logro de los aprendizajes 
                                    fundamentales según su grado escolar. 
                                </p>
                            </span>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center text-center mt-5 pt-5">
                        <div className="col-12">
                            <p style={{backgroundColor: '#6ea9dd', color: 'black', paddingBottom: '2rem'}}>
                                Trabajar, analizar y discutir el <b>Reporte escuela</b> en el CTE es una oportunidad 
                                para generar comunidad y encontrar nuevas propuestas de mejora a nivel aula y a nivel escuela.
                            </p>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>)
}

export default SummarySection;

const styles = {
    th: {
        backgroundColor: '#E0E0E0',
        padding: '0.25rem',
        fontWeight: 'bold',
        fontSize: '0.8rem',
        textTransform: 'uppercase',
    },
    td: {
        padding: '0.25rem',
        fontSize: '0.8rem',
        textTransform: 'uppercase'
    },
    td2: {
        padding: '0.25rem',
        textAlign: 'left',
        textTransform: 'uppercase',
        fontSize: '14px',
        border: '1px solid gray'
    }
}
