import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TableOutline from '../../../../../components/Charts/TableOutline';
import TableGroups from '../../../../../components/Charts/TableGroups';
import Bar from '../../../../../components/Charts/Bar';
import HorizontalBar2 from '../../../../../components/Charts/HorizontalBar2';

const IDAITercerGrado = ({schoolId, currentSchoolYear}) => {

    let userState = useSelector(state => state.userState);

    return (<>

        {!(schoolId && currentSchoolYear)? null : <> 
            <div className="row align-items-center justify-content-center text-center">
                <div className="col-12">
                    <span style={{color: 'black', textAlign: 'justify'}}>
                        <p>
                            <b>5. Tercer grado</b>
                        </p>
                        <p>
                            El tercer grado es un momento clave: en este ciclo escolar las y 
                            los estudiantes deben concretar su proceso de alfabetización para 
                            que puedan acceder a más conocimientos y habilidades en los grados posteriores. 
                        </p>
                    </span>
                </div>
            </div>

            <div className="row align-items-center justify-content-center text-center">
                <div className="col-12">
                    <span style={{color: 'black', textAlign: 'justify'}}>
                        <p>
                            <b>IDAI</b>
                        </p>
                        <p>
                            Si eres docente de tercero, en la siguiente tabla debes ubicar la información 
                            que corresponda a tu grupo. Reflexiona sobre la distribución de aprendices 
                            por nivel: ¿Cuántos estudiantes aún se encuentran en los niveles A y B? 
                            Estas niñas y niños están por transitar a primaria alta. Tu objetivo 
                            es dirigir a la totalidad del grupo a nivel D, es decir, que todas y 
                            todos logren su alfabetización en el transcurso del ciclo escolar. 
                            ¿Qué estrategias propones para conseguirlo? 
                        </p>
                        <p>
                            Comparte qué estrategias pondrás en marcha para lograr esta meta con tu figura 
                            directiva y con tus colegas para generar un diálogo pedagógico en torno al 
                            proceso de alfabetización.
                        </p>
                    </span>
                </div>
            </div>

            <div className="row align-items-center justify-content-center text-center mt-5">
                <div className="col-12 mt-5">
                    <span style={{color: 'black', textAlign: 'left'}}>
                        <p><i>Número de estudiantes por nivel y momento en el IDAI,  tercer grado</i></p>
                    </span>

                    <TableGroups
                        title=""
                        style = {{
                            fontSize: 'small',
                            pagination: false
                        }}
                        requestObj = {{
                            configParams: { // DataSource (custom, table)
                                custom: 'diagnostico2023'
                            },
                            dateRange: null,
                            filters: {'$and': [
                                {schoolId}, 
                                {grado: 3},
                                {lastSchoolYearId: currentSchoolYear.id}
                            ]},
                            dimensions: [
                                {name: 'grado', label: 'Grado', minWidth: '20px', priority: 1},
                                {name: 'label', label: 'Grupo', minWidth: '20px', priority: 2},
                            ],
                            breakdownDimension: null,
                            metrics: [],
                            customMetrics: [
                                {name: 'inicialA', label: 'A', header: 'Inicial', minWidth: '10px', priority: 3, aggregation: 'sum', custom: 'IF(inicial="A", 1, 0)'},
                                {name: 'inicialB', label: 'B', header: 'Inicial', minWidth: '10px', priority: 4, aggregation: 'sum', custom: 'IF(inicial="B", 1, 0)'},
                                {name: 'inicialC', label: 'C', header: 'Inicial', minWidth: '10px', priority: 5, aggregation: 'sum', custom: 'IF(inicial="C", 1, 0)'},
                                {name: 'inicialD', label: 'D', header: 'Inicial', minWidth: '10px', priority: 6, aggregation: 'sum', custom: 'IF(inicial="D", 1, 0)'},
                                {name: 'intermedioA', label: 'A', header: 'Intermedio', minWidth: '10px', priority: 8, aggregation: 'sum', custom: 'IF(intermedio="A", 1, 0)'},
                                {name: 'intermedioB', label: 'B', header: 'Intermedio', minWidth: '10px', priority: 9, aggregation: 'sum', custom: 'IF(intermedio="B", 1, 0)'},
                                {name: 'intermedioC', label: 'C', header: 'Intermedio', minWidth: '10px', priority: 10, aggregation: 'sum', custom: 'IF(intermedio="C", 1, 0)'},
                                {name: 'intermedioD', label: 'D', header: 'Intermedio', minWidth: '10px', priority: 11, aggregation: 'sum', custom: 'IF(intermedio="D", 1, 0)'},
                                {name: 'finalA', label: 'A', header: 'Final', minWidth: '10px', priority: 13, aggregation: 'sum', custom: 'IF(final="A", 1, 0)'},
                                {name: 'finalB', label: 'B', header: 'Final', minWidth: '10px', priority: 14, aggregation: 'sum', custom: 'IF(final="B", 1, 0)'},
                                {name: 'finalC', label: 'C', header: 'Final', minWidth: '10px', priority: 15, aggregation: 'sum', custom: 'IF(final="C", 1, 0)'},
                                {name: 'finalD', label: 'D', header: 'Final', minWidth: '10px', priority: 16, aggregation: 'sum', custom: 'IF(final="D", 1, 0)'},
                                {name: 'recordCount', label: 'Total estudiantes por grado', hidden:true, minWidth: '200px', aggregation: 'count', custom: '*'},
                            ],
                            order: {
                                dimension: 'label',
                                ascendent: true,
                            },
                            orderBy: 'ORDER BY label ASC',
                        }}
                    />
                </div>
            </div>

            <div className="row align-items-center justify-content-center text-center mt-5 pt-5">
                <div className="col-12">
                    <p style={{backgroundColor: '#5da0f1', color: 'white', paddingBottom: '2rem'}}>
                        Ahora que sabes cómo está tu grupo, consulta las páginas 5 y 6 del Manual del personal docente e identifica 
                        y aplica las actividades recomendadas para avanzar de un nivel a otro.
                    </p>
                </div>
            </div>

            <div className="row align-items-center justify-content-center text-center">
                <div className="col-12">
                    <span style={{color: 'black', textAlign: 'justify'}}>
                        <p>
                            <b>ICE</b>
                        </p>
                        <p>
                            ¿Qué relación tienen el IDAI y el ICE en tercer grado? <b>Recuerda que ICE se 
                            aplica únicamente a estudiantes de tercer grado con nivel D en el IDAI</b>, 
                            debido a que el uso de las convencionalidades de la escritura es el siguiente 
                            paso para consolidar el proceso de alfabetización. 
                        </p>
                        <p>
                            Por medio de la aplicación del ICE, te será posible identificar los conocimientos
                            ―nivel y uso― que tiene cada aprendiz sobre las convencionalidades de 
                            la escritura, por ejemplo, el empleo de mayúsculas, minúsculas y signos de 
                            puntuación. El nivel obtenido por cada estudiante te facilitará la elección 
                            de las secuencias didácticas que se proponen en Aventuras en papel. 
                        </p>
                        <p>
                            En la siguiente tabla se muestra la distribución de estudiantes por su 
                            nivel de uso de las  convencionalidades de la escritura según el ICE.
                        </p>
                    </span>
                </div>
            </div>

            <div className="row align-items-center justify-content-center text-center mt-5">
                <div className="col-12 mt-5">
                    <span style={{color: 'black', textAlign: 'left'}}>
                        <p><i>Número de estudiantes por nivel y momento en el ICE, tercer grado</i></p>
                    </span>

                    <TableGroups
                        title=""
                        style = {{
                            fontSize: 'small',
                            pagination: false
                        }}
                        requestObj = {{
                            configParams: { // DataSource (custom, table)
                                custom: 'convenciones2023'
                            },
                            dateRange: null,
                            filters: {'$and': [
                                {schoolId}, 
                                {grado: 3},
                                {lastSchoolYearId: currentSchoolYear.id}
                            ]},
                            dimensions: [
                                {name: 'grado', label: 'Grado', minWidth: '20px', priority: 1},
                                {name: 'label', label: 'Grupo', minWidth: '20px', priority: 2},
                            ],
                            breakdownDimension: null,
                            metrics: [],
                            customMetrics: [
                                {name: 'inicialA', label: 'A', header: 'Inicial', minWidth: '10px', priority: 3, aggregation: 'sum', custom: 'IF(inicial="1", 1, 0)'},
                                {name: 'inicialB', label: 'B', header: 'Inicial', minWidth: '10px', priority: 4, aggregation: 'sum', custom: 'IF(inicial="2", 1, 0)'},
                                {name: 'inicialC', label: 'C', header: 'Inicial', minWidth: '10px', priority: 5, aggregation: 'sum', custom: 'IF(inicial="3", 1, 0)'},
                                {name: 'inicialD', label: 'D', header: 'Inicial', minWidth: '10px', priority: 6, aggregation: 'sum', custom: 'IF(inicial="4", 1, 0)'},
                                {name: 'intermedioA', label: 'A', header: 'Intermedio', minWidth: '10px', priority: 8, aggregation: 'sum', custom: 'IF(intermedio="1", 1, 0)'},
                                {name: 'intermedioB', label: 'B', header: 'Intermedio', minWidth: '10px', priority: 9, aggregation: 'sum', custom: 'IF(intermedio="2", 1, 0)'},
                                {name: 'intermedioC', label: 'C', header: 'Intermedio', minWidth: '10px', priority: 10, aggregation: 'sum', custom: 'IF(intermedio="3", 1, 0)'},
                                {name: 'intermedioD', label: 'D', header: 'Intermedio', minWidth: '10px', priority: 11, aggregation: 'sum', custom: 'IF(intermedio="4", 1, 0)'},
                                {name: 'finalA', label: 'A', header: 'Final', minWidth: '10px', priority: 13, aggregation: 'sum', custom: 'IF(final="1", 1, 0)'},
                                {name: 'finalB', label: 'B', header: 'Final', minWidth: '10px', priority: 14, aggregation: 'sum', custom: 'IF(final="2", 1, 0)'},
                                {name: 'finalC', label: 'C', header: 'Final', minWidth: '10px', priority: 15, aggregation: 'sum', custom: 'IF(final="3", 1, 0)'},
                                {name: 'finalD', label: 'D', header: 'Final', minWidth: '10px', priority: 16, aggregation: 'sum', custom: 'IF(final="4", 1, 0)'},
                                {name: 'recordCount', label: 'Total estudiantes por grado', hidden:true, minWidth: '200px', aggregation: 'count', custom: '*'},
                            ],
                            order: {
                                dimension: 'label',
                                ascendent: true,
                            },
                            orderBy: 'ORDER BY label ASC',
                        }}
                    />
                </div>
            </div>

            <div className="row align-items-center justify-content-center text-center mt-5 pt-5">
                <div className="col-12">
                    <p style={{backgroundColor: '#cce3f5', color: '#0077ba', paddingBottom: '2rem'}}>
                        Recuerda que la construcción de convencionalidades de la lengua escrita no es un proceso lineal. El uso, el contraste y la reflexión 
                        continua son lo que llevan a las personas a convertirse en usuarias competentes de la lengua escrita. 
                    </p>
                </div>
            </div>

            <div className="row align-items-center justify-content-center text-center mt-5 pt-5">
                <div className="col-12">
                    <span style={{color: 'black', textAlign: 'left'}}>
                        <p><i>Observa los resultados del IDAI y del ICE de tercer grado a nivel escuela</i></p>
                        <p>
                            En la siguiente gráfica se muestra la distribución de las y los estudiantes 
                            de acuerdo con sus niveles de alfabetización. En colectivo reflexionen, 
                            acuerden y tomen acciones para lograr que al menos 80% de la población 
                            estudiantil de tercer grado consolide su proceso de alfabetización al 
                            momento de la segunda aplicación del IDAI. 
                        </p>
                        <p>Aplicación IDAI 3°</p>
                    </span>
                </div>
            </div>

            <div className="row align-items-center justify-content-center text-center">
                <div className="col-10">
                    <HorizontalBar2
                        title="Tercer Grado"
                        horizontal={true}
                        porcent={true}
                        requestObj = {{
                            configParams: { // DataSource (custom, table)
                                custom: 'responses2023'
                            },
                            dateRange: null,
                            filters: {'$and': [
                                {schoolId}, 
                                {lastSchoolYearId: currentSchoolYear.id},
                                {grado: 3},
                                {'$or': [
                                    {formId: 40},
                                    {formId: 41},
                                    {formId: 42},
                                ]},
                            ]},
                            dimension: {name: 'formName', label: ''},
                            dimensions: [{name: 'formId', label: ''}],
                            breakdownDimension: {name: 'customScore', label: 'Nivel'},
                            metrics: [],
                            customMetrics: [],
                            colorLabels: {
                                'A': '#4285F4', 
                                'B': '#EA4336', 
                                'C': '#FBBD05', 
                                'D': '#34A853',
                            },
                            order: {
                                dimension: 'formId',
                                ascendent: true,
                            },
                            orderBy: 'ORDER BY formId ASC',
                        }}
                    />
                </div>
            </div>

            <div className="row align-items-center justify-content-center text-center mt-5 pt-5">
                <div className="col-12 mt-5">
                    <span style={{color: 'black', textAlign: 'left'}}>
                        <p><i>Observa la distribución de estudiantes en los niveles del uso de las convencionalidades de la escritura según el ICE. </i></p>
                    </span>
                </div>
            </div>

            <div className="row align-items-center justify-content-center text-center mb-5">
                <div className="col-10">
                    <HorizontalBar2
                        title="Tercer Grado"
                        horizontal={true}
                        porcent={true}
                        requestObj = {{
                            configParams: { // DataSource (custom, table)
                                custom: 'responses2023'
                            },
                            dateRange: null,
                            filters: {'$and': [
                                {schoolId}, 
                                {lastSchoolYearId: currentSchoolYear.id },
                                {grado: 3},
                                {'$or': [
                                    {formId: 43},
                                    {formId: 44},
                                    {formId: 45},
                                ]},
                            ]},
                            dimension: {name: 'formName', label: ''},
                            dimensions: [{name: 'formId', label: ''}],
                            breakdownDimension: {name: 'customScore', label: 'Nivel'},
                            metrics: [],
                            customMetrics: [],
                            colorLabels: {
                                '1': '#4285F4', 
                                '2': '#EA4336', 
                                '3': '#FBBD05', 
                                '4': '#34A853',
                            },
                            order: {
                                dimension: 'formId',
                                ascendent: true,
                            },
                            orderBy: 'ORDER BY formId ASC',
                        }}
                    />
                </div>
            </div>

            <div className="row align-items-center justify-content-center text-center">
                <div className="col-12">
                    <span style={{color: 'black', textAlign: 'justify'}}>
                        <p>
                            Tu objetivo es observar la cantidad de estudiantes en cada nivel y 
                            proponer estrategias en conjunto con tus colegas docentes y el personal 
                            directivo  para que avancen en el uso de convencionalidades de la escritura.
                        </p>
                        <ul>
                            <li>¿Qué secuencias didácticas fortalecerán el aprendizaje de convencionalidades? </li>
                            <li>¿Qué estrategias de mediación puedes integrar a tu práctica que favorezcan la reflexión sobre las convencionalidades de la lengua escrita?</li>
                        </ul>
                    </span>
                </div>
            </div>

             <div className="row align-items-center justify-content-center text-center mt-5 pt-5">
                <div className="col-12">
                    <p style={{color: '#003866', paddingBottom: '2rem', fontSize: '16px', fontWeight:'bold'}}>
                        Recuerda que la mejora de aprendizajes basada en evidencias es un proceso continuo. 
                        En el mes de marzo podrás consultar el segundo Reporte escuela con los datos capturados 
                        correspondientes al Momento 2 del IDAI y el ICE para contrastar los resultados y el 
                        avance del proceso de alfabetización de las y los estudiantes.
                        <br/>
                        <br/>
                        ¡Todas y todos contribuimos a que las niñas y los niños de nuestra escuela logren 
                        los aprendizajes fundamentales en lengua!
                    </p>
                </div>
            </div>
        </>}
    </>);
}

export default IDAITercerGrado;
