import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TableOutline from '../../../../../components/Charts/TableOutline';
import TableGroups from '../../../../../components/Charts/TableGroups';
import Bar from '../../../../../components/Charts/Bar';
import HorizontalBar2 from '../../../../../components/Charts/HorizontalBar2';
import Bar3 from '../../../../../components/Charts/Bar3';

const IDAISegundoGrado = ({schoolId, currentSchoolYear}) => {

    let userState = useSelector(state => state.userState);

    return (<>

        {!(schoolId && currentSchoolYear)? null : <> 
            <div className="row align-items-center justify-content-center text-center  mt-5 pt-5">
                <div className="col-12 mt-5 pt-5">
                    <span style={{color: 'black', textAlign: 'justify'}}>
                        <p>
                            <b>6. Resultados del IDAI, segundo grado</b>
                        </p>
                    </span>
                </div>
            </div>

            <div className="row align-items-center justify-content-center text-center mt-5">
                <div className="col-10">
                    <Bar3
                        title="Segundo grado"
                        requestObj = {{
                            configParams: { // DataSource (custom, table)
                                custom: 'responses2023Enrol'
                            },
                            dateRange: null,
                            filters: {'$and': [
                                {schoolId}, 
                                {schoolYearId: currentSchoolYear.id},
                                {grado: 2},
                                {'$or': [
                                    {formId: 53},
                                    {formId: 54},
                                    {formId: 55},
                                ]},
                            ]},
                            dimension: {name: 'formName', label: ''},
                            dimensions: [{name: 'formId', label: ''}],
                            breakdownDimension: {name: 'customScore', label: 'Nivel'},
                            metrics: [],
                            customMetrics: [],
                            colorLabels: {
                                'A': '#ff0000', 
                                'B': '#feff00', 
                                'C': '#bfe1b0', 
                                'D': '#02b342',
                            },
                            order: {
                                dimension: 'formId',
                                ascendent: true,
                            },
                            orderBy: 'ORDER BY formId ASC',
                        }}
                    />
                </div>
            </div>
        </>}
    </>);
}

export default IDAISegundoGrado;
