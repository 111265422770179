import React from 'react';
import imgGoals1 from'../../../../assets/unesco/img/goals1.jpg';
import HorizontalBar2 from '../../../../components/Charts/HorizontalBar2';
import ChartDonut2 from '../../../../components/Charts/Donut2';
import ChartDonut from '../../../../components/Charts/Donut';

const PorcentajesSection = ({props, schoolId, currentSchoolYear}) => {

    return (<div className="container mb-5">

        {!schoolId? null : <div className="row align-items-center justify-content-center text-center">
            <div className="col-12">

                <div className="container">
                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12">
                            <span style={{color: 'black', textAlign: 'justify'}}>
                                <p>
                                    <b>1. Porcentaje de estudiantes evaluados</b>
                                </p>
                            </span>
                        </div>
                    </div>
                    
                    {!(schoolId && currentSchoolYear)? null : 
                        <div className="row align-items-center justify-content-center text-center">

                            <div className="col-4" style={{margin: '0 -4rem'}}>
                                <ChartDonut2 
                                    title="Primer grado"
                                    porcent={true}
                                    requestObj = {{
                                        configParams: { // DataSource (custom, table)
                                            custom: 'studentEvaluacionesCiclo4Momento1'
                                        },
                                        dateRange: null,
                                        filters: {'$and': [
                                            {schoolId}, 
                                            {schoolYearId: currentSchoolYear.id},
                                            {grade: 1},
                                        ]},
                                        dimensions: [
                                            {name: 'evaluado', label: 'Evaluado'},
                                        ],
                                        metrics: [],
                                        customMetrics: [
                                            {name: 'recordCount', label: 'Total', hidden:true, minWidth: '200px', aggregation: 'count', custom: '*'},
                                        ],
                                        order: {
                                            dimension: 'evaluado',
                                            ascendent: true,
                                        },
                                        orderBy: 'ORDER BY evaluado ASC',
                                    }}
                                />
                            </div>

                            <div className="col-4" style={{margin: '0 -4rem'}}>
                                <ChartDonut2 
                                    title="Segundo grado"
                                    porcent={true}
                                    requestObj = {{
                                        configParams: { // DataSource (custom, table)
                                            custom: 'studentEvaluacionesCiclo4Momento1'
                                        },
                                        dateRange: null,
                                        filters: {'$and': [
                                            {schoolId}, 
                                            {schoolYearId: currentSchoolYear.id},
                                            {grade: 2},
                                        ]},
                                        dimensions: [
                                            {name: 'evaluado', label: 'Evaluado'},
                                        ],
                                        metrics: [],
                                        customMetrics: [
                                            {name: 'recordCount', label: 'Total', hidden:true, minWidth: '200px', aggregation: 'count', custom: '*'},
                                        ],
                                        order: {
                                            dimension: 'evaluado',
                                            ascendent: true,
                                        },
                                        orderBy: 'ORDER BY evaluado ASC',
                                    }}
                                />
                            </div>
                            
                            <div className="col-4" style={{margin: '0 -4rem'}}>
                                <ChartDonut2 
                                    title="Tercer grado"
                                    porcent={true}
                                    requestObj = {{
                                        configParams: { // DataSource (custom, table)
                                            custom: 'studentEvaluacionesCiclo4Momento1'
                                        },
                                        dateRange: null,
                                        filters: {'$and': [
                                            {schoolId}, 
                                            {schoolYearId: currentSchoolYear.id},
                                            {grade: 3},
                                        ]},
                                        dimensions: [
                                            {name: 'evaluado', label: 'Evaluado'},
                                        ],
                                        metrics: [],
                                        customMetrics: [
                                            {name: 'recordCount', label: 'Total', hidden:true, minWidth: '200px', aggregation: 'count', custom: '*'},
                                        ],
                                        order: {
                                            dimension: 'evaluado',
                                            ascendent: true,
                                        },
                                        orderBy: 'ORDER BY evaluado ASC',
                                    }}
                                />
                            </div>
                            
                            <div className="col-5" style={{margin: '0 -3rem'}}>
                                <ChartDonut2 
                                    title="A nivel escuela"
                                    porcent={true}
                                    requestObj = {{
                                        configParams: { // DataSource (custom, table)
                                            custom: 'studentEvaluacionesCiclo4Momento1'
                                        },
                                        dateRange: null,
                                        filters: {'$and': [
                                            {schoolId}, 
                                            {schoolYearId: currentSchoolYear.id}
                                        ]},
                                        dimensions: [
                                            {name: 'evaluado', label: 'Evaluado'},
                                        ],
                                        metrics: [],
                                        customMetrics: [
                                            {name: 'recordCount', label: 'Total', hidden:true, minWidth: '200px', aggregation: 'count', custom: '*'},
                                        ],
                                        order: {
                                            dimension: 'evaluado',
                                            ascendent: true,
                                        },
                                        orderBy: 'ORDER BY evaluado ASC',
                                    }}
                                />
                            </div>
                        </div>
                    }

                    <div className="row align-items-center justify-content-center text-center mt-5">
                        <div className="col-12">
                            <p style={{color: 'black'}}>NOTA: Recordemos que el objetivo compartido es aplicar las evaluaciones formativas a todas las niñas y niños de 1° a 3°</p>
                        </div>
                    </div>

                </div>

            </div>
        </div> }
    </div>)
}

export default PorcentajesSection;


const styles = {
    td2: {
        padding: '0.25rem',
        textAlign: 'center',
        fontSize: '14px',
        border: '1px solid gray'
    }
}

